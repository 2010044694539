import React from "react";
import styled from "styled-components";
import Layout from "@components/Layouts/MainLayout";
//import Background from "@images/services/intelligence-background.png";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Col, Row } from "react-bootstrap";
import { Fade } from "react-reveal";
import { motion } from "framer-motion";
import intelligence_vid from "../../images/services/Vngle_intelligence.mp4"

const intelligence = () => {
  return (
    <Layout>
      <HeroContainer className="main-banner" style={{ position: 'relative', overflow: 'hidden' }}>
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }}
        >
          <source
            src={intelligence_vid}
            type="video/mp4"
            className="login-vid"
          />
          <track kind="captions" />
        </video>
        
        <Container className="d-flex justify-content-center flex-column" style={{ position: 'relative', zIndex: 1 }}>
          <motion.div
            animate={{ y: [100, 0], opacity: [0, 1] }}
            transition={{ duration: 1.3, delay: 1 }}
          >
            <div className="main-title">
              <h1 className="display-4 fw-bolder" style={{color:'white', textAlign:'left'}}>
              Grassroots Intelligence
              </h1>
            </div>
            <div>
              <h2 style={{color: 'white'}}><strong>Transform local insights into actionable data for smarter decisions.</strong></h2>
            </div>
          </motion.div>
        </Container>
      </HeroContainer>

      <MaContainer>
        <div className="mt display-3">
            <h1>We fuel organizational engagement with local communities - providing in-depth, real-time data on community trends, sentiments, and emerging issues. </h1>
        </div>

        <SplitSection background="" className="section2 gy-3">
          <Col md={6}>
            <Fade left>
              <div className="content p-md-5">
                <p className="sup display-2">
                  <Heading>
                  Our intelligence service enables data-backed decision making to strengthen campaigns, social initiatives, and market strategies. 
                  </Heading>
                </p>
              </div>
            </Fade>
          </Col>
          <Col className="image" md={6}>
            <Fade right>
              <StaticImage
                src="../../images/services/town_grid.png"
                alt="Town with grids"
                style={{width: '100%', height: 'auto'}}
              />
              
            </Fade>
          </Col>
        </SplitSection>
      </MaContainer>

      <div className="bg-dark">
        <HContainer>
          <p className="font-sans-serif">
            How Grassroots Intelligence Works:
          </p>
          <div className="font-serif">
            <p> 1. Community Insight Collection </p>
            <p>We gather data through grassroots powered surveying, interviews, and  sentiment analysis to help you better understand the needs and opinions of communities.</p>
            <br />
            <p> 2. Data Analysis and Reporting</p>
            <p>We help teams transform raw insights into clear, actionable data through custom reports tailored to your organizational goals and challenges.</p>
            <br />
            <p>3. Localized Trend Mapping </p>
            <p>We track hyper-local trends to inform the development of strategies to help you get ahead of emerging opportunities and challenges within specific regions or groups.</p>
          </div>
        </HContainer>
      </div>
      

     {/* <div>
      <HContainer>
      <div className="content p-md-5">
         <p className="sup four">
            <Heading>
                {" "}
                  <strong>Make confident, data-backed decisions rooted in grassroots knowledge.</strong> With Grassroots Intelligence, organizations can tailor their plan of action around local insights, ensuring that their messaging and outreach are aligned with community needs.   {" "}
              </Heading>
              

            </p>
            <FullImageContainer>
            <StaticImage
                      src="../../images/services/work-meeting.png"
                      alt="Man writing on sheet"
              /> 
            </FullImageContainer>
        </div>

        <div>
        <p className="sup four">
            <Heading>
              {" "}
                Grassroots Intelligence brings you closer to understanding the perspective of target audiences. By blending storytelling with data, we help you unlock trends, identify opportunities, and better respond to needs.{" "}
              </Heading>
            </p>

        </div>
      </HContainer>
     </div> */}
      <MaContainer>
              <SplitSection background="" className="section2 gy-3">
                <Col md={{ span: 6, order: "last" }}>
                  <Fade left>
                    <div className="content p-md-5">
                      <p className="sup">
                        <Heading>
                          {" "}
                          <strong>Make confident decisions using local insights to align plans, messaging, and outreach with community needs.</strong>  {" "}
                        </Heading>
                      </p>
                    </div>
                  </Fade>
                </Col>
                <Col className="image" md={6}>
                  <Fade right>
                    <StaticImage
                      src="../../images/services/work_meeting.png"
                      alt="Man writing on sheet"
                      style={{width: '100%', height: '100%'}}
                    />
                  </Fade>
                </Col>
              </SplitSection>
      
              <SplitSection background="" className="section2 gy-3">
                <Col md={6}>
                  <Fade left>
                    <div className="content p-md-5">
                      <p className="sup">
                        <Heading>
                          {" "}
                          By blending storytelling with data, we help you understand target audiences, uncover trends, and identify opportunities.{" "}
                        </Heading>
                      </p>
                    </div>
                  </Fade>
                </Col>
                <Col className="image" md={6}>
                  <Fade right>
                    <StaticImage
                      src="../../images/services/city-data.png"
                      alt="City surrounded by data"
                      style={{width: '100%', height: '100%'}}
                    />
                  </Fade>
                </Col>
              </SplitSection>
      
              <SplitSection background="" className="section2 gy-3">
                <Col md={{ span: 6, order: "last" }}>
                  <Fade left>
                    <div className="content p-md-5">
                      <p className="sup">
                        <Heading>
                          {" "}
                          With actionable  ground-level insights, we give you transparency into local behaviors and opinions that you can only get through local experts.  {" "}
                        </Heading>
                      </p>
                    </div>
                  </Fade>
                </Col>
                <Col className="image" md={6}>
                  <Fade right>
                    <StaticImage
                      src="../../images/services/stephen.jpeg"
                      alt="Quality Score"
                    />
                  </Fade>
                </Col>
              </SplitSection>
            </MaContainer>

      {/* <SubForm /> */}

      <div>
        <HContainer>
          <p className="font-sans-serif four">
            Who Needs Grassroots Intelligence?
          </p>
          <div className="font-serif">
           <ul>
            <p className="four">
              <li>
              Decision-makers, market planners, and strategists seeking hyper-local data.
              </li>
            </p>
            <p className="four" style={{color: "black"}}>
              <li>
              Organizations looking to align initiatives with the needs of their communities.
              </li>
            </p>
           </ul>
          </div>
        </HContainer>
      </div>

      <Container className="mt-3">
        <FullImageContainer>
      <StaticImage
                      src="../../images/services/town-connected.png"
                      alt="Town connected by dots"
                    />
          </FullImageContainer>
        <br /><br />

        <MaContainer>
  <div
    style={{
      display: 'flex',
      flexDirection: 'row', // Arrange children side by side
      alignItems: 'center', // Align items vertically in the center
      justifyContent: 'center', // Center the content horizontally
      gap: '1rem', // Adds space between the text and the button
    }}
  >
{/*   Button on top of image   */}
    <h1
      style={{
        fontFamily: '"Playfair Display", serif',
        fontSize: '2rem',
        fontWeight: 'bold',
        color: '#000',
        margin: 0, // Removes default margin
      }}
    > 
      Want to leverage Grassroots Intelligence?
      <br/>
       <strong>Partner with Vngle</strong>
      
    </h1>

    <a
      href="https://forms.gle/1G2TP6DgYY7kvzc16"
      target="_blank"
      style={{
        textDecoration: 'none', // Removes underline from link
      }}
    >
      <button
        className="btn btn-primary"
        style={{marginRight: '2rem'}}
      >
        Contact Us 
      </button>
    </a>
  </div>
</MaContainer>

        <small>
          Disclaimer: The Grassroots Intelligence service is a data-driven offering that provides actionable insights sourced directly from local communities. While we work to uncover trends and local perspectives for our clients, this service operates entirely independently from Vngle's newsroom operations. We remain committed to preserving the integrity of our editorial goals and nonpartisan reporting.
        </small>
      </Container>
    </Layout>
  );
};

const HeroContainer = styled.div`
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  h1 {
    font-family: "Playfair Display", serif;
    margin-bottom: 0rem;
    text-align: center;
  }
  .main-title {
    max-width: 1000rem;
    display: flex;
    color: black;
  }
  .btn-container {
    padding: 2rem;
  }

  .animated-btn {
    color: white;
    padding: 15px 30px;
    border-radius: 30px;
    font-weight: bold;
  }

  .hero-img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    opacity: 0.9;
  }
`;

const Heading = styled.h1`
  font-family: "Inter", sans-serif;
  font-weight: ${(props) => props.weight || "bold"};
  font-size: clamp(2.5rem, 5vw, ${(props) => props.size || "3rem"}); /* Responsive font size */
  text-align: ${(props) => (props.center ? "center" : "left")};
  line-height: ${(props) => props.lineHeight || "1.2"};
  color: ${(props) => props.color || "#333"}; /* Default color fallback */

  @media (max-width: 768px) {
    text-align: center; /* Center text on smaller screens */
    line-height: 1.4; /* Increase line spacing for readability */
  }
`;


const MaContainer = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  align-items: center;

  .mt {
    margin-bottom: 4rem;

    .r {
      color: #e612d0;
    }
  }

  .gatsby-image-wrapper {
    height: 100%;
    max-width: 100%; /* Ensure images fit the container */
    margin: 0 auto;

    img {
      border-radius: 10px;
      width: 100%; /* Make images responsive */
      height: auto; /* Maintain aspect ratio */
    }
  }

  h1 {
    font-weight: bold;
    font-family: "Playfair Display", serif;
    text-align: center;
    font-size: clamp(2rem, 5vw, 3rem); /* Responsive font size */
  }

  @media (max-width: 768px) {
    .mt {
      margin-bottom: 2rem;
    }

    h1 {
      font-size: clamp(1.8rem, 4.5vw, 2.5rem);
    }
  }
`;
const HContainer = styled(Container)`
  padding-top: 3rem;
  padding-bottom: 1rem;

  p {
    color: white;
    font-weight: bold;
    font-size: clamp(1.5rem, 2.5vw, 3rem); /* Scales based on viewport size */
    text-align: left;

    .b {
      color: #000000; /* Fixed invalid color */
      text-align: center;
    }

    .f {
      color: var(--bs-primary);
    }
  }

  .one,
  .two,
  .third {
    color: #1ee4f0;
    text-align: center;
    animation: flip 3s infinite;
    animation-delay: calc(0.3s * var(--i));
    animation-iteration-count: 5;
    font-size: clamp(1rem, 2.5vw, 2rem); /* Responsive animation text size */
  }

  .four {
    color: black;
    font-weight: bold;
    font-size: clamp(1.5rem, 2.5vw, 3rem); /* Responsive font size */
    text-align: left;
  }

  @keyframes flip {
    0%,
    80% {
      transform: rotateY(360deg);
    }
  }

  /* Adjustments for smaller devices */
  @media (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 0.5rem;

    p {
      font-size: clamp(1rem, 4vw, 2rem); /* Smaller font size for mobile */
      text-align: center; /* Center-align for better readability */
    }

    .four {
      font-size: clamp(1rem, 4vw, 2rem); /* Adjusted for smaller screens */
    }

    .one,
    .two,
    .third {
      font-size: clamp(0.8rem, 2vw, 1.5rem); /* Smaller font size for animations */
    }
  }
`;

const SplitSection = styled(Row)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .content {
    background: ${(props) => props.background || "#f9f9f9"}; /* Default background */
    padding: 2rem;
    border-radius: 10px;
    height: 100%;


  @media (max-width: 768px) { /* Adjust layout for tablets and smaller screens */
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;

    .content {
      padding: 1.5rem; /* Reduce padding for smaller screens */
    }
  }

  @media (max-width: 47rem) { /* Further adjustments for screens narrower than 47rem */
    &.section2 {
      flex-direction: column-reverse; /* Stack content */
    }

    .content {
      margin-bottom: 1rem; /* Add spacing between stacked elements */
    }
  }
`;

const FullImageContainer = styled.div`
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);

  .gatsby-image-wrapper {
    width: 100%;
    height: 100vh;

    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }
  }

  @media (max-width: 768px) {
    .gatsby-image-wrapper {
      height: 50vh; /* Adjust height for smaller screens */
    }
  }
`;

export default intelligence;